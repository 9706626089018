import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import ContactUsComponent from 'components/@contactus';

function AboutUs() {
  return (
    <Layout hideDownload={true}>
      <Seo title="Pintarnya Kerja | Kontak Kami" />
      <ContactUsComponent />
    </Layout>
  );
}

export default AboutUs;
